var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { CreateOrderTemplate, FetchOrderFull } from './../graphql/order/index';
import { types, flow, getSnapshot } from 'mobx-state-tree';
import { AsyncActionStatus, MaybeEmptyModel } from './common';
import { toast } from 'react-toastify';
import { i18n } from '../i18n';
import { FetchOrder, CreateOrder, UpdateOrder, CreateOrderRoute, CreateOrderCargo, PublishOrder, UpdateOrderRoute, UpdateOrderCargo, DeleteOrderCargo, CreateOrderVehicle, CreateOrderCargoProductType, CreateOrderCargoPalletType, CreateOrderCargoPackage, DuplicateOrder, FetchOrderIndex, UpdateOrderQuoteDetails, UpdateOrderCargoPackage, UpdateOrderCargoProductType, UpdateOrderCargoPalletType, UpdateOrderVehicle, CopyOrder, OrderPDF, } from '../graphql';
import { Order, OrderRoute, OrderLocation, OrderRouteType, } from '../models';
import browserHistory from '../browserHistory';
import { FetchOrderCargoPackage, FetchOrderCargoProductType, FetchOrderVehicle } from '../graphql';
import { DeleteOrderTransportation, CreateOrderTransportation, UpdateOrderTransportation, FetchOrderTransportation, } from '../graphql/order/orderTransportation';
import { OrderTransportation } from '../models';
import { KEYS } from '../i18n';
import { NAVIGATION } from '../HOK/LocationHistory';
import { toDateOnly } from '../utils';
import { FetchOrderQuoteDetails } from '../graphql';
import { FetchUploads } from '../graphql';
import { CreateHtmlTemplate, UpdateHtmlTemplate } from '../graphql/htmlTemplate';
import moment from 'moment';
import { googleTagManager } from '../utils1';
import { FetchOrderPreparingStatuses, UpdateOrderPreparing } from '../graphql/order/orderPreparing';
import { OrderPreparingStatuses } from '../models/OrderPreparingStatuses';
import { CreateOrderCargoTaintedCode, FetchOrderCargoTaintedCode, UpdateOrderCargoTaintedCode, } from '../graphql/order/orderCargoTaintedCode';
var timeOut = {};
function checkOrderCargo(reqBody, isPallet) {
    __spread([
        {
            name: 'amount',
            resolver: function (v) { return parseInt(v); },
        },
        {
            name: 'weight',
            resolver: function (v) { return parseFloat(v); },
        },
        {
            name: 'volume',
            resolver: function (v) { return parseFloat(v); },
        },
        {
            name: 'height',
            resolver: function (v) { return parseFloat(v); },
        },
        {
            name: 'width',
            resolver: function (v) { return parseFloat(v); },
        },
        {
            name: 'length',
            resolver: function (v) { return parseFloat(v); },
        }
    ], (!isPallet
        ? [
            {
                name: 'productType',
                resolver: function (v) { return (v && v._id) || (v === null && v); },
            },
            {
                name: 'palletType',
                resolver: function (v) { return (v && v._id) || (v === null && v); },
            },
            {
                name: 'package',
                resolver: function (v) { return (v && v._id) || (v === null && v); },
            },
        ]
        : [])).forEach(function (el) {
        if (reqBody.hasOwnProperty(el.name) &&
            typeof reqBody[el.name] !== 'undefined' &&
            (reqBody[el.name] || ['productType', 'palletType', 'package'].includes(el.name))) {
            if (el.resolver)
                reqBody[el.name] = el.resolver(reqBody[el.name]);
        }
        else {
            delete reqBody[el.name];
        }
    });
    return reqBody;
}
export var OrderStoreFactory = function (_a) {
    var client = _a.client;
    return types
        .compose(AsyncActionStatus, types.model('OrderStore', {
        order: types.optional(types.maybeNull(Order), function () { return Order.create({ cargoType: '' }); }),
        preparingStatuses: types.maybeNull(types.optional(types.array(MaybeEmptyModel(OrderPreparingStatuses)), function () { return []; })),
        updatedAt: types.optional(types.number, 1),
        isOrderUpdating: types.optional(types.boolean, false),
        isOrderPublishing: types.optional(types.boolean, false),
        isOrderLoading: types.optional(types.boolean, false),
    }))
        .actions(function (self) { return ({
        loadPreparingStatuses: flow(function (_id) {
            var res, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        if (self.preparingStatuses.length)
                            return [2 /*return*/];
                        return [4 /*yield*/, client.mutate({
                                mutation: FetchOrderPreparingStatuses,
                                variables: {
                                    filter: { fields: [], limit: 100, sortBy: 'order', sortDir: 'asc' },
                                },
                            })];
                    case 1:
                        res = _a.sent();
                        self.preparingStatuses = res.data.orderPreparingStatuses.data;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        toast.error(e_1.message || e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        duplicateOrder: flow(function (_id) {
            var result_1, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: DuplicateOrder,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result_1 = _a.sent();
                        browserHistory.push("/orders/" + result_1.data.duplicateOrder._id);
                        googleTagManager('event', 'izi_form_order_duplicate_from_template', __assign({ event_name: "order_duplicate_from_template" }, [
                            'sorting_countryPickup',
                            'sorting_pickupAt',
                            'sorting_countryDelivery',
                            'sorting_deliveryAt',
                            'sorting_totalWeight',
                            'orderId',
                        ]
                            .filter(function (e) { return result_1.data.duplicateOrder[e]; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a["order_" + e] = result_1.data.duplicateOrder[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})));
                        self.order = result_1.data.duplicateOrder;
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_order')));
                        return [3 /*break*/, 4];
                    case 2:
                        e_2 = _a.sent();
                        toast.error(e_2.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        copyOrder: flow(function (source) {
            var _id, result, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        _id = self.order._id.toString();
                        return [4 /*yield*/, client.mutate({
                                mutation: CopyOrder,
                                variables: {
                                    input: {
                                        target: _id,
                                        source: source,
                                    },
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_order')));
                        self.order = JSON.parse(JSON.stringify(result.data.copyOrder));
                        self.updatedAt = Date.now();
                        return [3 /*break*/, 4];
                    case 2:
                        e_3 = _a.sent();
                        toast.error(e_3.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        preCreateTransportation: flow(function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        self.isOrderLoading = true;
                        return [4 /*yield*/, self.createOrderCargo({}, true)];
                    case 1:
                        _a.sent();
                        self.isOrderLoading = false;
                        return [2 /*return*/];
                }
            });
        }),
        createOrder: flow(function (_a, additional, noToast) {
            var beforeCreate, usedTypes_1, dirtyRoutes, routes_1, result, updates, e_4;
            var _id = _a._id, input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        beforeCreate = getSnapshot(self.order);
                        self.isOrderUpdating = true;
                        googleTagManager('event', 'izi_form_order_create', { event_name: "order_create" });
                        usedTypes_1 = [];
                        dirtyRoutes = __spread([
                            additional
                        ], beforeCreate.transportations
                            .map(function (e) { return e.routes.find(function (r) { return r.origin && r.origin._id && r.email.length && r.startDate && r.endDate; }); })
                            .filter(function (e) { return e; })).filter(function (e) {
                            if (!e || usedTypes_1.includes(e.type))
                                return false;
                            usedTypes_1.push(e.type);
                            return true;
                        });
                        routes_1 = [];
                        dirtyRoutes.forEach(function (dirtyRoute) {
                            if (dirtyRoute) {
                                var _id_1 = dirtyRoute._id, warehouse = dirtyRoute.warehouse, orderTransportation = dirtyRoute.orderTransportation, origin_1 = dirtyRoute.origin, rangeDates = dirtyRoute.rangeDates, updatedAt = dirtyRoute.updatedAt, remainRoute = __rest(dirtyRoute, ["_id", "warehouse", "orderTransportation", "origin", "rangeDates", "updatedAt"]);
                                routes_1.push(__assign(__assign({}, remainRoute), { origin: origin_1._id }));
                            }
                        });
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrder,
                                variables: {
                                    input: Object.assign(__assign({}, input), input.price
                                        ? {
                                            price: parseFloat(input.price.toString()),
                                        }
                                        : {}, input.vehicleType
                                        ? {
                                            vehicleType: input.vehicleType && input.vehicleType._id,
                                        }
                                        : {}, input.certificates
                                        ? {
                                            certificates: (input.certificates || []).map(function (e) { return e._id || e; }),
                                        }
                                        : {}, self.order.noQuotes
                                        ? {
                                            noQuotes: true,
                                        }
                                        : {}, self.order.timePickupBooked
                                        ? {
                                            timePickupBooked: self.order.timePickupBooked,
                                        }
                                        : {}, self.order.timeIsPickup
                                        ? {
                                            timeIsPickup: self.order.timeIsPickup,
                                        }
                                        : {}, self.order.timeToSpend
                                        ? {
                                            timeToSpend: self.order.timeToSpend,
                                        }
                                        : {}, { transportations: [{ routes: routes_1.length ? routes_1 : [] }] }),
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        updates = result.data.createOrder;
                        self.order && self.order.reset(updates);
                        setTimeout(function () { return browserHistory.push("/orders/" + self.order._id); }, 100);
                        return [2 /*return*/, self.order];
                    case 2:
                        e_4 = _b.sent();
                        toast.error(e_4.message);
                        googleTagManager('event', 'izi-error', { value: e_4.message || e_4, event_name: 'error_order_create' });
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createOrderTemplate: flow(function (_a) {
            var result, e_5;
            var _id = _a._id, templateName = _a.templateName;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrderTemplate,
                                variables: {
                                    input: {
                                        source: _id,
                                        templateName: templateName,
                                    },
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        return [2 /*return*/, result.data.createOrderTemplate];
                    case 2:
                        e_5 = _b.sent();
                        toast.error(e_5.message);
                        return [3 /*break*/, 4];
                    case 3: return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateOrderPreparing: flow(function (input) {
            var order, result, e_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        order = self.order;
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderPreparing,
                                variables: { input: input },
                            })];
                    case 1:
                        result = _a.sent();
                        self.order &&
                            self.order.reset(__assign(__assign(__assign({}, getSnapshot(order)), input), result.data.updateOrderPreparing));
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_order')));
                        return [2 /*return*/, result.data.updateOrderPreparing];
                    case 2:
                        e_6 = _a.sent();
                        toast.error(e_6.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateOrder: flow(function (_a) {
            var order_1, keys, result, e_7;
            var _id = _a._id, input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, 5, 6]);
                        self.isOrderUpdating = true;
                        order_1 = self.order;
                        if (!(!order_1 || !order_1._id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, self.createOrder(input)];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        keys = Object.keys(input);
                        if (!keys.filter(function (e) { return e; }).length || keys.filter(function (e) { return input[e] === order_1[e]; }).length === keys.length) {
                            self.isOrderUpdating = false;
                            return [2 /*return*/, console.warn('No Changes')];
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrder,
                                variables: {
                                    input: Object.assign(__assign({ _id: _id || (order_1 && order_1._id) }, input), typeof input.price !== 'undefined'
                                        ? {
                                            price: parseFloat(input.price.toString()),
                                        }
                                        : {}, typeof input.forwarderOrderMarkUpPercentage !== 'undefined'
                                        ? {
                                            forwarderOrderMarkUpPercentage: parseFloat(input.forwarderOrderMarkUpPercentage.toString()),
                                        }
                                        : {}, input.vehicleType
                                        ? {
                                            vehicleType: (input.vehicleType || []).map(function (e) { return e._id; }),
                                        }
                                        : {}, input.certificates
                                        ? {
                                            certificates: (input.certificates || []).map(function (e) { return e._id || e; }),
                                        }
                                        : {}),
                                },
                            })];
                    case 3:
                        result = _b.sent();
                        self.order &&
                            self.order.reset(__assign(__assign(__assign({}, getSnapshot(order_1)), input), result.data.updateOrder));
                        console.log('self.isOrderUpdating');
                        return [2 /*return*/, self.order];
                    case 4:
                        e_7 = _b.sent();
                        toast.error(e_7.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        updateOrderQuoteItem: flow(function (_a) {
            var result, e_8;
            var _id = _a._id, input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderQuoteDetails,
                                variables: {
                                    input: Object.assign(__assign({ _id: _id }, input), typeof input.price !== 'undefined'),
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_order')));
                        return [2 /*return*/, result.data.updateOrderQuoteDetails];
                    case 2:
                        e_8 = _b.sent();
                        toast.error(e_8.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createHtmlTemplateItem: flow(function (input) {
            var result, e_9;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateHtmlTemplate,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_order')));
                        return [2 /*return*/, result.data.createHtmlTemplate];
                    case 2:
                        e_9 = _a.sent();
                        toast.error(e_9.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateHtmlTemplateItem: flow(function (_a) {
            var result, e_10;
            var _id = _a._id, input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateHtmlTemplate,
                                variables: {
                                    input: Object.assign(__assign({ _id: _id }, input)),
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_order')));
                        return [2 /*return*/, result.data.updateHtmlTemplate];
                    case 2:
                        e_10 = _b.sent();
                        toast.error(e_10.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        publishOrder: flow(function (_a, opt) {
            var order, result_2, e_11;
            if (opt === void 0) { opt = {}; }
            var _id = _a._id, noQuotes = _a.noQuotes, publishAs = _a.publishAs, input = __rest(_a, ["_id", "noQuotes", "publishAs"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isOrderPublishing = true;
                        order = self.order;
                        return [4 /*yield*/, client.mutate({
                                mutation: PublishOrder,
                                variables: {
                                    _id: _id,
                                    noQuotes: noQuotes,
                                    publishAs: publishAs,
                                },
                            })];
                    case 1:
                        result_2 = _b.sent();
                        if (result_2.data.publishOrder._id !== order._id) {
                            self.order &&
                                self.order.reset({
                                    _id: '',
                                });
                            toast.success(i18n.getResource(i18n.language, KEYS.INFO, 'new_order_from_template_published'));
                        }
                        googleTagManager('event', 'izi_form_order_publish', __assign({ event_name: "order_publish" }, [
                            'sorting_countryPickup',
                            'sorting_pickupAt',
                            'sorting_countryDelivery',
                            'sorting_deliveryAt',
                            'sorting_totalWeight',
                            'orderId',
                        ]
                            .filter(function (e) { return result_2.data.publishOrder[e]; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a["order_" + e] = result_2.data.publishOrder[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})));
                        self.order &&
                            self.order.reset(__assign(__assign(__assign(__assign({}, getSnapshot(self.order)), input), result_2.data.publishOrder), { publishedAt: new Date().toString() }));
                        if (!self.order.publishedAt)
                            self.order.publishedAt = new Date().toString();
                        if (opt.freeRedirect) {
                            browserHistory.push("/open-orders");
                        }
                        else {
                            browserHistory.push("/open-orders?search=" + (self.order.orderId || self.order._id));
                        }
                        return [2 /*return*/, self.order];
                    case 2:
                        e_11 = _b.sent();
                        googleTagManager('event', 'izi-error', { value: e_11.message || e_11, event_name: 'error_publish_order' });
                        toast.error(e_11.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderPublishing = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        onTemplate: flow(function (_a) {
            var order, duplicated, updates, result, e_12;
            var _id = _a._id, noQuotes = _a.noQuotes, input = __rest(_a, ["_id", "noQuotes"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, 5, 6]);
                        self.isOrderUpdating = true;
                        order = self.order;
                        return [4 /*yield*/, client.mutate({
                                mutation: DuplicateOrder,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        duplicated = _b.sent();
                        updates = {};
                        if (!duplicated.data.duplicateOrder.templateName)
                            updates.templateName = input.templateName || duplicated.data.duplicateOrder.orderId;
                        updates.isTemplate = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrder,
                                variables: {
                                    input: __assign({ _id: duplicated.data.duplicateOrder._id }, updates),
                                },
                            })];
                    case 2:
                        _b.sent();
                        delete input.templateName;
                        delete input.isTemplate;
                        return [4 /*yield*/, client.mutate({
                                mutation: PublishOrder,
                                variables: {
                                    _id: _id,
                                    noQuotes: noQuotes,
                                },
                            })];
                    case 3:
                        result = _b.sent();
                        self.order &&
                            self.order.reset(__assign(__assign(__assign({}, getSnapshot(order)), input), result.data.publishOrder));
                        googleTagManager('event', 'izi_form_order_duplicate_from_template_onTemplate', __assign({ event_name: "order_duplicate_from_template_onTemplate" }, [
                            'sorting_countryPickup',
                            'sorting_pickupAt',
                            'sorting_countryDelivery',
                            'sorting_deliveryAt',
                            'sorting_totalWeight',
                            'orderId',
                        ]
                            .filter(function (e) { return self.order[e]; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a["order_" + e] = self.order[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})));
                        return [2 /*return*/, self.order];
                    case 4:
                        e_12 = _b.sent();
                        toast.error(e_12.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        loadOrderFull: flow(function (_id, noStore) {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, client.query({
                            query: FetchOrderFull,
                            variables: {
                                _id: _id,
                            },
                        })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.order];
                }
            });
        }),
        loadOrder: flow(function (_id, noStore) {
            var result, _a, _b, _c, _d, e_13;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 2, 3, 4]);
                        if (!noStore && self.order && self.order._id === _id) {
                            return [2 /*return*/, console.warn('already loaded')];
                        }
                        self.isOrderLoading = true;
                        _b = (_a = JSON).parse;
                        _d = (_c = JSON).stringify;
                        return [4 /*yield*/, client.query({
                                query: FetchOrder,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result = _b.apply(_a, [_d.apply(_c, [_e.sent()])]);
                        if (!result.data.order.transportations.length) {
                            result.data.order.transportations.push(OrderTransportation.create());
                        }
                        // self.isOrderLoading = false;
                        if (noStore)
                            return [2 /*return*/, result.data.order];
                        self.order = Order.create(result.data.order);
                        return [2 /*return*/, self.order];
                    case 2:
                        e_13 = _e.sent();
                        console.log(e_13);
                        setTimeout(function () { return NAVIGATION.reset(); });
                        browserHistory.push('/orders/create');
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadOrderIndex: flow(function (_id) {
            var result, e_14;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isOrderLoading = true;
                        return [4 /*yield*/, client.query({
                                query: FetchOrderIndex,
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.orderIndex.order];
                    case 2:
                        e_14 = _a.sent();
                        console.log(e_14);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadOrderQuote: flow(function (_id) {
            var result, e_15;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isOrderLoading = true;
                        return [4 /*yield*/, client.query({
                                query: FetchOrderQuoteDetails,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.orderQuoteDetails];
                    case 2:
                        e_15 = _a.sent();
                        console.log(e_15);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadOrderPDF: flow(function (_id) {
            var result, e_16;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isOrderLoading = true;
                        return [4 /*yield*/, client.query({
                                query: OrderPDF,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.orderPdf];
                    case 2:
                        e_16 = _a.sent();
                        console.log(e_16);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadOrderQuoteUploads: flow(function (_id) {
            var result, e_17;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isOrderLoading = true;
                        return [4 /*yield*/, client.query({
                                query: FetchUploads,
                                variables: {
                                    filter: {
                                        fields: [
                                            { name: 'parent', value: _id },
                                            { name: 'canAccess', value: true },
                                        ],
                                        limit: 3,
                                    },
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.uploads.data];
                    case 2:
                        e_17 = _a.sent();
                        console.log(e_17);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        clearOrder: function (order) {
            if (self.order) {
                self.order && self.order.reset(__assign(__assign({}, order), { _id: '' }));
            }
        },
        checkOrderId: function () {
            if (self.order.orderId)
                return;
            var name = '';
            var pickup = self.order.transportations[0].routes.find(function (e) { return e.type === OrderRouteType.Preferred; });
            var unload = self.order.transportations[self.order.transportations.length - 1].routes.find(function (e) { return e.type === OrderRouteType.Delivery; });
            if (pickup &&
                unload &&
                (pickup.startDate || unload.endDate) &&
                pickup.origin &&
                pickup.origin._id &&
                unload.origin &&
                unload.origin._id) {
                name += "" + ((pickup.origin && pickup.origin.address.country) || '');
                name += "" + ((unload.origin && unload.origin.address.country) || '');
                name += "" + moment(new Date()).format('YY') || '';
                // name += (unload.endDate && `${moment(unload.endDate).format('YY')}`) || '';
                name += self.order.orderIndex < 10000 ? "0" + (self.order.indexId || '') : self.order.indexId || '';
                self.updateOrder({ orderId: name });
            }
        },
        createOrderRoute: flow(function (routeInput, noToast) {
            var rangeDates, origin_2, updatedAt, _input_1, order, beforeCreate, route, res, input_1, result, transportation, s, e_18;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, 5, 6]);
                        rangeDates = routeInput.rangeDates, origin_2 = routeInput.origin, updatedAt = routeInput.updatedAt, _input_1 = __rest(routeInput, ["rangeDates", "origin", "updatedAt"]);
                        self.isOrderUpdating = true;
                        order = self.order;
                        if (!(!order || !order._id)) return [3 /*break*/, 2];
                        beforeCreate = getSnapshot(self.order);
                        route = beforeCreate.transportations
                            .map(function (e) { return e.routes.find(function (r) { return r.origin && r.origin._id && r.email.length && r.startDate && r.endDate; }); })
                            .filter(function (e) { return e; })[0];
                        if (route) {
                            self.order.transportations.forEach(function (t) {
                                t.routes.forEach(function (r) {
                                    if (r.type === _input_1.type) {
                                        ['email', 'startDate', 'endDate'].forEach(function (key, index) {
                                            if (_input_1[key])
                                                r[key] = index > 0 ? toDateOnly(_input_1[key]) : _input_1[key];
                                        });
                                    }
                                });
                            });
                        }
                        return [4 /*yield*/, self.createOrder({}, routeInput, noToast)];
                    case 1:
                        res = _a.sent();
                        if (route || res)
                            return [2 /*return*/, res];
                        _a.label = 2;
                    case 2:
                        input_1 = Object.assign(__assign(__assign({}, _input_1), { orderTransportation: _input_1.orderTransportation._id, order: order && order._id }), rangeDates && rangeDates.find(function (e) { return e; })
                            ? {
                                startDate: rangeDates[0],
                                endDate: rangeDates[1],
                            }
                            : {}, origin_2
                            ? {
                                origin: origin_2._id,
                            }
                            : {});
                        ['startDate', 'endDate'].forEach(function (e) {
                            if (input_1[e])
                                input_1[e] = toDateOnly(input_1[e]);
                        });
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrderRoute,
                                variables: {
                                    input: input_1,
                                },
                            })];
                    case 3:
                        result = _a.sent();
                        if (order) {
                            transportation = (order.transportations || []).find(function (e) { return e._id === _input_1.orderTransportation._id; });
                            if (!transportation) {
                                s = getSnapshot(order);
                                order.reset(__assign(__assign({}, s), { transportations: [_input_1.orderTransportation] }));
                                transportation = _input_1.orderTransportation;
                            }
                            transportation.updatedAt = new Date().toString();
                            transportation.routes.push(OrderRoute.create(__assign(__assign({}, result.data.createOrderRoute), { origin: result.data.createOrderRoute.origin && OrderLocation.create(result.data.createOrderRoute.origin) })));
                        }
                        //Update Apollo Cache
                        if (timeOut.orderRoute)
                            clearTimeout(timeOut.orderRoute);
                        timeOut.orderRoute = setTimeout(function () {
                            client.resetStore();
                        }, 1000);
                        //Update Apollo Cache
                        return [2 /*return*/, result.data.createOrderRoute];
                    case 4:
                        e_18 = _a.sent();
                        toast.error(e_18.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        updateOrderRoute: flow(function (record) {
            var origin_3, rangeDates, updatedAt, _input, input_2, result_3, snapshot, e_19;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        if (!record._id) {
                            delete record._id;
                            return [2 /*return*/, self.createOrderRoute(record)];
                        }
                        origin_3 = record.origin, rangeDates = record.rangeDates, updatedAt = record.updatedAt, _input = __rest(record, ["origin", "rangeDates", "updatedAt"]);
                        input_2 = Object.assign(__assign(__assign({}, _input), { origin: origin_3 && origin_3._id }), rangeDates
                            ? {
                                startDate: rangeDates[0],
                                endDate: rangeDates[1],
                            }
                            : {}, origin_3
                            ? {
                                origin: origin_3._id,
                            }
                            : {}, _input.orderTransportation
                            ? {
                                orderTransportation: _input.orderTransportation._id,
                            }
                            : {});
                        ['startDate', 'endDate'].forEach(function (e) {
                            if (input_2[e])
                                input_2[e] = toDateOnly(input_2[e]);
                        });
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderRoute,
                                variables: {
                                    input: input_2,
                                },
                            })];
                    case 1:
                        result_3 = _a.sent();
                        if (self.order) {
                            snapshot = getSnapshot(self.order);
                            self.order &&
                                self.order.reset(__assign(__assign({}, snapshot), { transportations: snapshot.transportations.map(function (e) {
                                        return __assign(__assign({}, e), { routes: e.routes.map(function (route) {
                                                if (route._id === result_3.data.updateOrderRoute._id) {
                                                    return __assign(__assign({}, route), result_3.data.updateOrderRoute);
                                                }
                                                return route;
                                            }) });
                                    }) }));
                        }
                        return [2 /*return*/, result_3.data.updateOrderRoute];
                    case 2:
                        e_19 = _a.sent();
                        toast.error(e_19.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        self.checkOrderId();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createOrderCargo: flow(function (_a, noToast) {
            var order, isNew, _b, reqBody_1, result_4, snapshot, orderSnapshot, transportation, e_20;
            var _c;
            var _id = _a._id, updatedAt = _a.updatedAt, input = __rest(_a, ["_id", "updatedAt"]);
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 6, 7, 8]);
                        self.isOrderUpdating = true;
                        order = self.order;
                        if (!(!order || !order._id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, self.createOrder({}, null, noToast)];
                    case 1:
                        _d.sent();
                        _d.label = 2;
                    case 2:
                        isNew = false;
                        if (!(!input.orderTransportation || !input.orderTransportation._id)) return [3 /*break*/, 4];
                        _b = input;
                        return [4 /*yield*/, self.createOrderTransportation({}, noToast)];
                    case 3:
                        _b.orderTransportation = _d.sent();
                        isNew = true;
                        _d.label = 4;
                    case 4:
                        reqBody_1 = checkOrderCargo(__assign(__assign({}, input), { orderTransportation: input.orderTransportation._id, order: order && order._id }));
                        if (isNew && !reqBody_1._id) {
                            reqBody_1._id = (_c = self.order.transportations.find(function (t) { return t._id === input.orderTransportation._id; })) === null || _c === void 0 ? void 0 : _c.cargoes[0]._id;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: reqBody_1._id ? UpdateOrderCargo : CreateOrderCargo,
                                variables: {
                                    input: reqBody_1,
                                },
                            })];
                    case 5:
                        result_4 = _d.sent();
                        if (order) {
                            if (isNew) {
                                snapshot = getSnapshot(order);
                                order.reset(__assign(__assign({}, snapshot), { transportations: snapshot.transportations.map(function (e) {
                                        return __assign(__assign({}, e), { updatedAt: new Date().toString(), cargoes: e.cargoes.map(function (e) {
                                                if (e._id === reqBody_1._id) {
                                                    return __assign(__assign({}, e), result_4.data.updateOrderCargo);
                                                }
                                                return e;
                                            }) });
                                    }) }));
                            }
                            else {
                                orderSnapshot = getSnapshot(order);
                                transportation = (orderSnapshot.transportations || []).find(function (e) { return input.orderTransportation._id === e._id; });
                                if (!transportation) {
                                    order.reset(__assign(__assign({}, orderSnapshot), { transportations: [input.orderTransportation] }));
                                }
                                else {
                                    order.reset(__assign(__assign({}, orderSnapshot), { transportations: orderSnapshot.transportations.map(function (e) { return (__assign(__assign({}, e), (e._id === input.orderTransportation._id && {
                                            cargoes: __spread(e.cargoes, [
                                                __assign(__assign({}, input), result_4.data.createOrderCargo),
                                            ]),
                                        }))); }) }));
                                }
                            }
                        }
                        return [2 /*return*/, result_4];
                    case 6:
                        e_20 = _d.sent();
                        toast.error(e_20.message);
                        throw e_20.message;
                    case 7:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        }),
        updateOrderCargo: flow(function (input, opt) {
            var order, reqBody, result_5, snapshot, e_21;
            if (opt === void 0) { opt = {}; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        if (!input._id) {
                            return [2 /*return*/, self.createOrderCargo(input)];
                        }
                        order = self.order;
                        reqBody = checkOrderCargo(__assign(__assign({}, input), { order: order && order._id }));
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderCargo,
                                variables: {
                                    input: Object.assign(reqBody, reqBody.orderTransportation ? { orderTransportation: reqBody.orderTransportation._id } : null),
                                },
                            })];
                    case 1:
                        result_5 = _a.sent();
                        if (order) {
                            snapshot = getSnapshot(order);
                            order.reset(__assign(__assign({}, snapshot), { transportations: snapshot.transportations.map(function (e) {
                                    return __assign(__assign({}, e), { updatedAt: new Date().toString(), cargoes: e.cargoes.map(function (e) {
                                            if (e._id === input._id) {
                                                return __assign(__assign({}, e), result_5.data.updateOrderCargo);
                                            }
                                            return e;
                                        }) });
                                }) }));
                        }
                        return [2 /*return*/, result_5];
                    case 2:
                        e_21 = _a.sent();
                        toast.error(e_21.message);
                        throw e_21.message;
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        deleteOrderCargo: flow(function (input) {
            var order, snapshot, e_22;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        order = self.order;
                        return [4 /*yield*/, client.mutate({
                                mutation: DeleteOrderCargo,
                                variables: {
                                    _id: input._id,
                                },
                            })];
                    case 1:
                        _a.sent();
                        if (order) {
                            snapshot = getSnapshot(order);
                            order.reset(__assign(__assign({}, snapshot), { transportations: snapshot.transportations.map(function (e) {
                                    return __assign(__assign({}, e), { updatedAt: new Date().toString(), cargoes: e.cargoes.filter(function (e) { return e._id !== input._id; }) });
                                }) }));
                        }
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_deleted')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.ORDER_FORM, 'create_order_cargo_label')));
                        return [3 /*break*/, 4];
                    case 2:
                        e_22 = _a.sent();
                        toast.error(e_22.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadTransportation: flow(function (_id) {
            var result, e_23;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, client.query({
                                query: FetchOrderTransportation,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.orderTransportation];
                    case 2:
                        e_23 = _a.sent();
                        console.log(e_23);
                        toast.error(e_23.message);
                        return [3 /*break*/, 4];
                    case 3: return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createOrderTransportation: flow(function (input, noToast) {
            var existOrder, result, snapshot, e_24;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, 5, 6]);
                        self.isOrderUpdating = true;
                        if (!(!self.order || !self.order._id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, self.createOrder({}, null, noToast)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        ['startDate', 'endDate'].forEach(function (e) {
                            if (input && input[e])
                                input[e] = toDateOnly(input[e]);
                        });
                        existOrder = self.order;
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrderTransportation,
                                variables: {
                                    input: __assign(__assign({}, input), { order: existOrder._id, prevRoutes: existOrder
                                            ? existOrder.transportations[existOrder.transportations.length - 1].routes.map(function (route) { return route._id; })
                                            : null }),
                                },
                            })];
                    case 3:
                        result = _a.sent();
                        if (existOrder) {
                            snapshot = getSnapshot(existOrder);
                            existOrder.reset(__assign(__assign({}, snapshot), { transportations: __spread(snapshot.transportations.filter(function (e) { return e._id; }), [
                                    result.data.createOrderTransportation,
                                ]).filter(function (e) { return e; }) }));
                        }
                        return [2 /*return*/, result.data.createOrderTransportation];
                    case 4:
                        e_24 = _a.sent();
                        toast.error(e_24.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        updateOrderTransportations: flow(function (_a, options) {
            var existOrder, result, snapshot, _b, routes, cargoes, updates_1, e_25;
            if (options === void 0) { options = {}; }
            var input = __rest(_a, []);
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 4, 5, 6]);
                        if (!!input._id) return [3 /*break*/, 2];
                        delete input._id;
                        return [4 /*yield*/, self.createOrderTransportation(input)];
                    case 1: return [2 /*return*/, _c.sent()];
                    case 2:
                        self.isOrderUpdating = true;
                        existOrder = self.order;
                        ['startDate', 'endDate'].forEach(function (e) {
                            if (input[e])
                                input[e] = toDateOnly(input[e]);
                        });
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderTransportation,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 3:
                        result = _c.sent();
                        if (existOrder) {
                            snapshot = getSnapshot(existOrder);
                            _b = result.data.updateOrderTransportation, routes = _b.routes, cargoes = _b.cargoes, updates_1 = __rest(_b, ["routes", "cargoes"]);
                            existOrder.reset(__assign(__assign({}, snapshot), { transportations: snapshot.transportations.map(function (e) {
                                    if (e._id === updates_1._id) {
                                        if (options.keys) {
                                            return __assign(__assign({}, e), options.keys.map(function (e) {
                                                var _a;
                                                return (_a = {}, _a[e] = updates_1[e], _a);
                                            }).reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
                                        }
                                        return __assign(__assign({}, e), updates_1);
                                    }
                                    return e;
                                }) }));
                        }
                        return [2 /*return*/, result.data.updateOrderTransportation];
                    case 4:
                        e_25 = _c.sent();
                        console.log(e_25);
                        toast.error(e_25.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        deleteOrderTransportations: flow(function (_a) {
            var existOrder, snapshot, e_26;
            var _id = _a._id;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        existOrder = self.order;
                        return [4 /*yield*/, client.mutate({
                                mutation: DeleteOrderTransportation,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        _b.sent();
                        if (existOrder) {
                            snapshot = getSnapshot(existOrder);
                            existOrder.reset(__assign(__assign({}, snapshot), { transportations: snapshot.transportations.filter(function (e) { return e._id !== _id; }) }));
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        e_26 = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = true;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createOrderVehicle: flow(function (_a) {
            var order, res, data, e_27;
            var _id = _a._id, input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, 5, 6]);
                        self.isOrderUpdating = true;
                        order = self.order;
                        if (!(!order || !order._id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, self.createOrder({})];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [4 /*yield*/, client.mutate({
                            mutation: CreateOrderVehicle,
                            variables: {
                                input: __assign(__assign({}, input), { order: order && order._id }),
                            },
                        })];
                    case 3:
                        res = _b.sent();
                        try {
                            data = client.readQuery({ query: FetchOrderVehicle });
                            client.writeQuery({
                                query: FetchOrderVehicle,
                                data: {
                                    orderVehicles: {
                                        count: data.orderVehicles.count + 1,
                                        data: __spread([res.data.createOrderVehicle], data.orderVehicles.data),
                                    },
                                },
                            });
                        }
                        catch (e) { }
                        return [2 /*return*/, res.data.createOrderVehicle];
                    case 4:
                        e_27 = _b.sent();
                        toast.error(e_27.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        updateOrderVehicle: flow(function (_a) {
            var order, res_1, data, e_28;
            var _id = _a._id, name = _a.name, description = _a.description, input = __rest(_a, ["_id", "name", "description"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, 5, 6]);
                        self.isOrderUpdating = true;
                        order = self.order;
                        if (!(!order || !order._id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, self.createOrder({})];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [4 /*yield*/, client.mutate({
                            mutation: UpdateOrderVehicle,
                            variables: {
                                input: {
                                    name: name,
                                    description: description,
                                    _id: _id,
                                },
                            },
                        })];
                    case 3:
                        res_1 = _b.sent();
                        try {
                            data = client.readQuery({ query: FetchOrderVehicle });
                            client.writeQuery({
                                query: FetchOrderVehicle,
                                data: {
                                    orderVehicles: {
                                        count: data.orderVehicles.count,
                                        data: data.orderVehicles.data.map(function (e) {
                                            if (e._id === res_1.data.updateOrderVehicle._id) {
                                                return res_1.data.updateOrderVehicle;
                                            }
                                            return e;
                                        }),
                                    },
                                },
                            });
                        }
                        catch (e) { }
                        return [2 /*return*/, res_1.data.updateOrderVehicle];
                    case 4:
                        e_28 = _b.sent();
                        toast.error(e_28.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        createOrderCargoProductType: flow(function (_a) {
            var res, data, e_29;
            var _id = _a._id, updatedAt = _a.updatedAt, input = __rest(_a, ["_id", "updatedAt"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        if (!input.orderCargo) {
                            delete input.orderCargo;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrderCargoProductType,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 1:
                        res = _b.sent();
                        try {
                            data = client.readQuery({ query: FetchOrderCargoProductType });
                            client.writeQuery({
                                query: FetchOrderCargoProductType,
                                data: {
                                    orderCargoProductTypes: {
                                        count: data.orderCargoProductTypes.count + 1,
                                        data: __spread(data.orderCargoProductTypes.data, [res.data.createOrderCargoProductType]),
                                    },
                                },
                            });
                            //Update Apollo Cache
                        }
                        catch (e) { }
                        return [2 /*return*/, res.data.createOrderCargoProductType];
                    case 2:
                        e_29 = _b.sent();
                        toast.error(e_29.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateOrderCargoProductType: flow(function (_a) {
            var res_2, data, e_30;
            var _id = _a._id, updatedAt = _a.updatedAt, name = _a.name, description = _a.description, input = __rest(_a, ["_id", "updatedAt", "name", "description"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        if (!input.orderCargo) {
                            delete input.orderCargo;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderCargoProductType,
                                variables: {
                                    input: {
                                        name: name,
                                        description: description,
                                        _id: _id,
                                    },
                                },
                            })];
                    case 1:
                        res_2 = _b.sent();
                        try {
                            data = client.readQuery({ query: FetchOrderCargoProductType });
                            client.writeQuery({
                                query: FetchOrderCargoProductType,
                                data: {
                                    orderCargoProductTypes: {
                                        count: data.orderCargoProductTypes.count,
                                        data: data.orderCargoProductTypes.data.map(function (e) {
                                            if (e._id === res_2.data.updateOrderCargoProductType._id) {
                                                return res_2.data.updateOrderCargoProductType;
                                            }
                                            return e;
                                        }),
                                    },
                                },
                            });
                            //Update Apollo Cache
                        }
                        catch (e) { }
                        return [2 /*return*/, res_2.data.updateOrderCargoProductType];
                    case 2:
                        e_30 = _b.sent();
                        toast.error(e_30.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createOrderCargoPalletType: flow(function (_a, transportationId, cargoId, isPublic) {
            var res_3, existOrder, snapshot, e_31;
            var _id = _a._id, updatedAt = _a.updatedAt, width = _a.width, length = _a.length, input = __rest(_a, ["_id", "updatedAt", "width", "length"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, 5, 6]);
                        self.isOrderUpdating = true;
                        if (!input.orderCargo) {
                            delete input.orderCargo;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrderCargoPalletType,
                                variables: {
                                    input: checkOrderCargo({
                                        width: width,
                                        length: length,
                                    }, true),
                                },
                            })];
                    case 1:
                        res_3 = _b.sent();
                        if (!!isPublic) return [3 /*break*/, 3];
                        return [4 /*yield*/, self.updateOrderCargo({ _id: cargoId, palletType: res_3.data.createOrderCargoPalletType })];
                    case 2:
                        _b.sent();
                        existOrder = self.order;
                        if (existOrder) {
                            snapshot = getSnapshot(existOrder);
                            existOrder.reset(__assign(__assign({}, snapshot), { transportations: snapshot.transportations.map(function (e) {
                                    if (e._id === transportationId) {
                                        return __assign(__assign({}, e), { cargoes: e.cargoes.map(function (cargo) {
                                                if (cargo._id === cargoId) {
                                                    return __assign(__assign({}, cargo), { palletType: res_3.data.updateOrderCargoPalletType });
                                                }
                                                return cargo;
                                            }) });
                                    }
                                    return e;
                                }) }));
                        }
                        _b.label = 3;
                    case 3: return [2 /*return*/, res_3.data.createOrderCargoPalletType];
                    case 4:
                        e_31 = _b.sent();
                        toast.error(e_31.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        updateOrderCargoPalletType: flow(function (_a, transportationId, cargoId, isPublic) {
            var res_4, existOrder, snapshot, e_32;
            var _id = _a._id, updatedAt = _a.updatedAt, name = _a.name, length = _a.length, width = _a.width, dimension = _a.dimension, input = __rest(_a, ["_id", "updatedAt", "name", "length", "width", "dimension"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        if (!input.orderCargo) {
                            delete input.orderCargo;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderCargoPalletType,
                                variables: {
                                    input: checkOrderCargo({
                                        length: length,
                                        width: width,
                                        _id: _id,
                                    }, true),
                                },
                            })];
                    case 1:
                        res_4 = _b.sent();
                        if (!isPublic) {
                            existOrder = self.order;
                            if (existOrder) {
                                snapshot = getSnapshot(existOrder);
                                existOrder.reset(__assign(__assign({}, snapshot), { transportations: snapshot.transportations.map(function (e) {
                                        if (e._id === transportationId) {
                                            return __assign(__assign({}, e), { cargoes: e.cargoes.map(function (cargo) {
                                                    if (cargo._id === cargoId) {
                                                        return __assign(__assign({}, cargo), { palletType: res_4.data.updateOrderCargoPalletType });
                                                    }
                                                    return cargo;
                                                }) });
                                        }
                                        return e;
                                    }) }));
                            }
                        }
                        return [2 /*return*/, res_4.data.updateOrderCargoPalletType];
                    case 2:
                        e_32 = _b.sent();
                        toast.error(e_32.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createOrderCargoPackage: flow(function (_a) {
            var res, data, e_33;
            var _id = _a._id, updatedAt = _a.updatedAt, name = _a.name, input = __rest(_a, ["_id", "updatedAt", "name"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        if (!input.orderCargo) {
                            delete input.orderCargo;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrderCargoPackage,
                                variables: {
                                    input: { name: name },
                                },
                            })];
                    case 1:
                        res = _b.sent();
                        try {
                            data = client.readQuery({ query: FetchOrderCargoPackage });
                            client.writeQuery({
                                query: FetchOrderCargoPackage,
                                data: {
                                    orderCargoPackages: {
                                        count: data.orderCargoPackages.count + 1,
                                        data: __spread([res.data.createOrderCargoPackage], data.orderCargoPackages.data),
                                    },
                                },
                            });
                        }
                        catch (e) { }
                        return [2 /*return*/, res.data.createOrderCargoPackage];
                    case 2:
                        e_33 = _b.sent();
                        console.log(e_33);
                        toast.error(e_33.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateOrderCargoPackage: flow(function (_a) {
            var res_5, data, e_34;
            var _id = _a._id, updatedAt = _a.updatedAt, name = _a.name, description = _a.description, input = __rest(_a, ["_id", "updatedAt", "name", "description"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        if (!input.orderCargo) {
                            delete input.orderCargo;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderCargoPackage,
                                variables: {
                                    input: { name: name, _id: _id },
                                },
                            })];
                    case 1:
                        res_5 = _b.sent();
                        try {
                            data = client.readQuery({ query: FetchOrderCargoPackage });
                            client.writeQuery({
                                query: FetchOrderCargoPackage,
                                data: {
                                    orderCargoPackages: {
                                        count: data.orderCargoPackages.count,
                                        data: data.orderCargoPackages.data.map(function (e) {
                                            if (e._id === res_5.data.updateOrderCargoPackage._id) {
                                                return res_5.data.updateOrderCargoPackage;
                                            }
                                            return e;
                                        }),
                                    },
                                },
                            });
                            //Update Apollo Cache
                        }
                        catch (e) { }
                        return [2 /*return*/, res_5.data.updateOrderCargoPackage];
                    case 2:
                        e_34 = _b.sent();
                        toast.error(e_34.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createOrderCargoTaintedCode: flow(function (_a) {
            var res, data, e_35;
            var _id = _a._id, updatedAt = _a.updatedAt, name = _a.name, input = __rest(_a, ["_id", "updatedAt", "name"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        if (!input.orderCargo) {
                            delete input.orderCargo;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrderCargoTaintedCode,
                                variables: {
                                    input: { name: name },
                                },
                            })];
                    case 1:
                        res = _b.sent();
                        try {
                            data = client.readQuery({ query: FetchOrderCargoTaintedCode });
                            client.writeQuery({
                                query: FetchOrderCargoTaintedCode,
                                data: {
                                    orderCargoTaintedCodes: {
                                        count: data.orderCargoTaintedCodes.count + 1,
                                        data: __spread([res.data.createOrderCargoTaintedCode], data.orderCargoTaintedCodes.data),
                                    },
                                },
                            });
                        }
                        catch (e) { }
                        return [2 /*return*/, res.data.createOrderCargoTaintedCode];
                    case 2:
                        e_35 = _b.sent();
                        console.log(e_35);
                        toast.error(e_35.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateOrderCargoTaintedCode: flow(function (_a) {
            var res_6, data, e_36;
            var _id = _a._id, updatedAt = _a.updatedAt, name = _a.name, description = _a.description, input = __rest(_a, ["_id", "updatedAt", "name", "description"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        if (!input.orderCargo) {
                            delete input.orderCargo;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderCargoTaintedCode,
                                variables: {
                                    input: { name: name, _id: _id },
                                },
                            })];
                    case 1:
                        res_6 = _b.sent();
                        try {
                            data = client.readQuery({ query: FetchOrderCargoTaintedCode });
                            client.writeQuery({
                                query: FetchOrderCargoTaintedCode,
                                data: {
                                    orderCargoTaintedCodes: {
                                        count: data.orderCargoTaintedCodes.count,
                                        data: data.orderCargoTaintedCodes.data.map(function (e) {
                                            if (e._id === res_6.data.updateOrderCargoTaintedCode._id) {
                                                return res_6.data.updateOrderCargoTaintedCode;
                                            }
                                            return e;
                                        }),
                                    },
                                },
                            });
                            //Update Apollo Cache
                        }
                        catch (e) { }
                        return [2 /*return*/, res_6.data.updateOrderCargoTaintedCode];
                    case 2:
                        e_36 = _b.sent();
                        toast.error(e_36.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
    }); })
        .named('OrderStore');
};
